import React from "react";
import "./App.css";

function App() {

  const spacing = {
    "margin-top": "16px",
  } as React.CSSProperties;

  return (
    <div className="App ">
      <div className="Text-container">
        <h1>Hi 👋</h1>
        <p>I'm Martin</p>
        <p>Mobile Software Engineer</p>
        <p>Copenhagen</p>
        <div style={spacing}>
          <a
            className="App-link"
            href="https://www.linkedin.com/in/martin-wiingaard/"
            target="_blank"
            rel="noopener noreferrer"
          >
            LinkedIn
          </a>
        </div>
      </div>
    </div>
  );
}

export default App;
